import { Link } from "gatsby"
import React, { useContext } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

import {
  AccountData,
  PeakSuiteData,
  PipefyData,
  QuandaryBuildData,
  QuandaryConnectData,
  QuickbaseData,
  TechConsultingData,
  WorkatoData,
} from "../components/internal-data/faq-data"

import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"

import {
  Accordion,
  AccordionContext,
  Card,
  useAccordionToggle,
} from "react-bootstrap"

const Banner = () => {
  //   const { heroImage } = useStaticQuery(
  //     graphql`
  //       {
  //         heroImage: file(relativePath: { eq: "new-images/contact-us-bg.jpg" }) {
  //           childImageSharp {
  //             gatsbyImageData(
  //               width: 1920
  //               layout: CONSTRAINED
  //               formats: [AUTO, WEBP, AVIF]
  //             )
  //           }
  //         }
  //       }
  //     `
  //   )

  //   const heroImageData = getImage(heroImage)

  //   // Use like this:
  //   const bgImage = convertToBgImage(heroImageData)

  return (
    <div
      // Tag="div"
      // {...bgImage}
      className="hero-banner hero-faq-banner"
      // preserveStackingContext
      // style={{
      //   backgroundPosition: "center",
      //   backgroundRepeat: "no-repeat",
      //   backgroundBlendMode: "overlay",
      // }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-7 mx-auto text-center">
            {/* <div className="hero-custom-content text-center hero-custom-content-section"> */}
            <div className="hero-custom-quickbase text-center hero-custom-content-section">
              <p className="h5 text-left-mobile text-light-blue text-uppercase font-weight-bold m-0">
                FAQ
              </p>
              <h1 className="text-left-mobile page-title font-weight-medium text-black mt-2">
                Find the Answers to All Your Frequently Asked Questions Below.
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function ContextAwareToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext)

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  )

  const isCurrentEventKey = currentEventKey === eventKey

  return (
    <Card.Header
      className={isCurrentEventKey ? "accordion-active" : "accordion-collapsed"}
    >
      <button
        aria-label="btn"
        type="button"
        onClick={decoratedOnClick}
        className={`m-0 border-0 bg-transparent w-100 text-left ${
          isCurrentEventKey ? "text-primary-gradient" : ""
        }`}
      >
        <p className="h5 text-left toggle-btn">{children}</p>
        <div>
          <div className="btn-plus-minus" />
        </div>
      </button>
    </Card.Header>
  )
}

const FAQ = ({ location }) => {
  return (
    <>
      <Layout banner={<Banner />} location={location}>
        <Seo
          title="Frequently Asked Questions | Quandary Consulting Group"
          description="These are the most commonly asked questions our customers have before they start working with us. Can't find your question? Contact us."
        />
        <section className="faq-wrapper" style={{ padding: "5rem 0" }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h2 className="mt-0">Account</h2>
                {AccountData.map(account => (
                  <Accordion defaultActiveKey="0" key={account.id}>
                    <Card>
                      <ContextAwareToggle
                        eventKey={account.id}
                        className="bg-transparent"
                      >
                        {account.title}
                      </ContextAwareToggle>
                      <Accordion.Collapse eventKey={account.id}>
                        <Card.Body>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: account.description,
                            }}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                ))}
              </div>
              <div className="col-lg-12">
                <h2>Quickbase</h2>
                {QuickbaseData.map(Quickbase => (
                  <Accordion defaultActiveKey="0" key={Quickbase.id}>
                    <Card>
                      <ContextAwareToggle
                        eventKey={Quickbase.id}
                        className="bg-transparent"
                      >
                        {Quickbase.title}
                      </ContextAwareToggle>
                      <Accordion.Collapse eventKey={Quickbase.id}>
                        <Card.Body>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: Quickbase.description,
                            }}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                ))}
              </div>
              <div className="col-lg-12">
                <h2>Workato</h2>
                {WorkatoData.map(workato => (
                  <Accordion defaultActiveKey="0" key={workato.id}>
                    <Card>
                      <ContextAwareToggle
                        eventKey={workato.id}
                        className="bg-transparent"
                      >
                        {workato.title}
                        {/* </Accordion.Toggle> */}
                      </ContextAwareToggle>
                      <Accordion.Collapse eventKey={workato.id}>
                        <Card.Body>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: workato.description,
                            }}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                ))}
              </div>
              <div className="col-lg-12">
                <h2>Pipefy</h2>
                {PipefyData.map(pipefy => (
                  <Accordion defaultActiveKey="0" key={pipefy.id}>
                    <Card>
                      <ContextAwareToggle
                        eventKey={pipefy.id}
                        className="bg-transparent"
                      >
                        {pipefy.title}
                        {/* </Accordion.Toggle> */}
                      </ContextAwareToggle>
                      <Accordion.Collapse eventKey={pipefy.id}>
                        <Card.Body>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: pipefy.description,
                            }}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                ))}
              </div>
              <div className="col-lg-12">
                <h2>peakSUITE</h2>
                {PeakSuiteData.map(peakSuite => (
                  <Accordion defaultActiveKey="0" key={peakSuite.id}>
                    <Card>
                      <ContextAwareToggle
                        eventKey={peakSuite.id}
                        className="bg-transparent"
                      >
                        {peakSuite.title}
                      </ContextAwareToggle>
                      <Accordion.Collapse eventKey={peakSuite.id}>
                        <Card.Body>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: peakSuite.description,
                            }}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                ))}
              </div>
              <div className="col-lg-12">
                <h2>Quandary Build</h2>
                {QuandaryBuildData.map(quandarybuild => (
                  <Accordion defaultActiveKey="0" key={quandarybuild.id}>
                    <Card>
                      <ContextAwareToggle
                        eventKey={quandarybuild.id}
                        className="bg-transparent"
                      >
                        {quandarybuild.title}
                      </ContextAwareToggle>
                      <Accordion.Collapse eventKey={quandarybuild.id}>
                        <Card.Body>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: quandarybuild.description,
                            }}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                ))}
              </div>
              <div className="col-lg-12">
                <h2>Quandary Connect</h2>
                {QuandaryConnectData.map(quandaryconnect => (
                  <Accordion defaultActiveKey="0" key={quandaryconnect.id}>
                    <Card>
                      <ContextAwareToggle
                        eventKey={quandaryconnect.id}
                        className="bg-transparent"
                      >
                        {quandaryconnect.title}
                        {/* </Accordion.Toggle> */}
                      </ContextAwareToggle>
                      <Accordion.Collapse eventKey={quandaryconnect.id}>
                        <Card.Body>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: quandaryconnect.description,
                            }}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                ))}
              </div>
              <div className="col-lg-12">
                <h2>Technology Consulting</h2>
                {TechConsultingData.map(techconsulting => (
                  <Accordion defaultActiveKey="0" key={techconsulting.id}>
                    <Card>
                      <ContextAwareToggle
                        eventKey={techconsulting.id}
                        className="bg-transparent"
                      >
                        {techconsulting.title}
                      </ContextAwareToggle>
                      <Accordion.Collapse eventKey={techconsulting.id}>
                        <Card.Body>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: techconsulting.description,
                            }}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                ))}
              </div>
            </div>
          </div>
        </section>
        <section id="quotes" style={{ padding: "5rem 0" }}>
          <div className="container">
            <div className="col-lg-12 text-center">
              <h2>Still Have Questions? Reach out!</h2>
              <p>We’ll get back to you ASAP.</p>
              <Link
                className="btn btn-primary btn-arrow mr-lg-4 mr-md-4 mt-4"
                to="/contact/"
              >
                Let's Chat
              </Link>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default FAQ
