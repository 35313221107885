export const AccountData = [
  {
    id: 1,
    title: "What does Quandary Consulting Group do?",
    description:
      "We help growing businesses continue to be successful by building lean systems that scale. Best of all, they cost a fraction of what custom software builds do. If you're feeling overwhelmed by increasing demands and need more time to focus on what really matters, we give you the systems that make that happen.",
    categories: "Account",
  },
  {
    id: 2,
    title: "Can you really give me more time?",
    description:
      "Yes. Every business has inefficient systems. If you’ve spent time looking for documents you couldn’t find, had to manually transfer data between spreadsheets, or waited for a report to load, you’ve wasted time. Our goal is to give you that time back by automating those processes.",
    categories: "Account",
  },
  {
    id: 3,
    title: "Can you really understand my unique, specific business needs?",
    description:
      "Every business is different. But the problems growing businesses face are actually very similar. As part of our pre-sales process, we spend a lot of time on research and discovery. Our goal is to map out your business and its pain points. Before we provide any recommendations or pricing, we learn your current state, current processes, and future goals. For expansive projects, Quandary offers a 1-2 day discovery session. This gives us the time we need to dive deep into your business processes and build a plan to optimize your systems.",
    categories: "Account",
  },
  {
    id: 4,
    title: "What if my developer leaves, who will know my system?",
    description:
      "There is always a minimum of two resources on every engagement. This protects our clients from turnover. It also removes the need for knowledge transfer and loss. Finally, it ensures you always have someone working on your account, no matter what happens.",
    categories: "Account",
  },
  {
    id: 5,
    title: "How responsive is your team?",
    description:
      "We have resources from coast to coast as well as overseas to provide consistent support at any hour. Our goal is to reply to any inquiries within a business day. We won’t keep you waiting.",
    categories: "Account",
  },
  {
    id: 6,
    title: "Where is my code hosted when I partner with Quandary?",
    description:
      "If you like, we can host your code for you without any problem. However, we always recommend that our clients host their own code. You own your code. That way, if you decide to work with a new partner, you’ll have ownership of the code. There’s never any obligation on your part to stay with us. This gives you more freedom.",
    categories: "Account",
  },
  {
    id: 7,
    title: "How can I find out about my project status?",
    description:
      "On every engagement, we create deliverable tracking in a system called <a aria-label='link' href='/peaksuite/peaksuite-trailhead/'>Trailhead</a>. Trailhead houses all of your projects, deliverables, details, due dates, and more. This gives you clear visibility into the progress of your project. You can make new requests in Trailhead, add notes to deliverables, and communicate with our team. We also have a minimum of one meeting per week with your development team. During that meeting, we review development, ask questions, make changes, and ensure everyone is on the same page.",
    categories: "Account",
  },
  {
    id: 8,
    title: "Will I be billed more if a project takes longer?",
    description:
      " Quandary offers price-protected engagements as protection for you. This means a clear project scope with agreed-upon deliverables and constant communication throughout the process. All at a set price. Quandary will guarantee your price and engagement at the start of your contract to ensure you receive every deliverable you agreed upon. <b>There are no surprise charges and add ons.</b>",
    categories: "Account",
  },
  {
    id: 9,
    title: "Why are you so responsive?",
    description:
      "Most frustrations happen because of miscommunications. That’s why we’re proactive. We want to work as an extension of your team. That means you need to be able to reach us like you would a team member. We encourage our clients to reach out early with any issues they may have so we can work on a resolution quickly.",
    categories: "Account",
  },
  {
    id: 10,
    title: "How successful is Quandary Consulting Group?",
    description:
      " We have a very successful adoption rate for the systems we create. The applications, integrations, and automations we build save our clients a LOT of time and money. It's why we have so many long-term clients. We think about their success in years, not days or weeks. Check out some of our awesome <a aria-label='link' href='/case-studies/'>Case Studies.</a>",
    categories: "Account",
  },
  {
    id: 11,
    title: "How can I trust that Quandary Consulting Group is knowledgeable?",
    description:
      "We understand how important trust is for our clients. That’s why we position ourselves as thought leaders in our industry. We regularly share our expertise and experiences with our community to help make it easier for growing businesses to scale their systems. Check out our extensive <a aria-label='link' href='/knowledge-base/'>Knowledge Base</a> Check out our up-to-date blog for the <a aria-label='link' href='/news/'>Latest News.</a>",
    categories: "Account",
  },
]

export const QuickbaseData = [
  {
    id: 1,
    title: "What is Quickbase?",
    description:
      "Quickbase is a low code program designed to help people build applications faster. Because it uses low code, there is minimal programming knowledge required. This makes custom builds cheaper and faster for any sized business.",
  },
  {
    id: 2,
    title: "How much does Quickbase cost per user?",
    description:
      "Quickbase user licenses depending on the number of users. Contact <a aria-label='link' href='mailto:sales@quandarycg.com'>sales@quandarycg.com</a> to help get a quote for your use case. Quandary also sells single-user licenses. These are more affordable than the 20 purchase minimum required through Quickbase directly.",
  },
  {
    id: 3,
    title: "Can I build and maintain applications myself?",
    description:
      "Absolutely! As part of our offerings, Quandary experts can train your builders while guiding you and your internal teams from a technical perspective.",
  },
  {
    id: 4,
    title: "What does it take to become a certified builder?",
    description:
      " Quickbase offers full courses on <a aria-label='link' href='https://university.Quickbase.com/'>Quickbase University</a>. You can take the Builder Certification and Expert Builder Certification to become a certified builder. If you’d like our tips and tricks on how to get certified, reach out to us at <a aria-label='link' href='mailto:info@quanadrycg.com'>info@quanadrycg.com.</a>",
  },
  {
    id: 5,
    title: "What is Pipelines?",
    description:
      "<a aria-label='link' href='https://help.Quickbase.com/pipelines/about_quick_base_pipelines.html'>Pipelines is an integration tool</a> built by Quickbase to integrate third-party systems. It’s an intuitive way to automate all your workflows. This both connects and gives you access to your data across multiple systems using one tool.",
  },
]

export const WorkatoData = [
  {
    id: 1,
    title: "What is Workato?",
    description:
      "Workato is an Integration Platform as a Service (iPaaS). It lets us take a short cut with writing code and hosting scripts that tap into your systems and automate your processes. Essentially, it allows businesses to create integrations in a fraction of the time (and for a fraction of the cost) needed to build one from scratch.",
  },
  {
    id: 2,
    title: "What is Workato’s pricing model?",
    description:
      "Workato has a base rate and an additional charge per recipe. This starts at <a aria-label='link' href='https://www.workato.com/pricing/'>$10,000 per year</a>. We specialize in optimizing recipes to ensure you’re not paying for something you don’t need.",
  },
  {
    id: 3,
    title: 'What is a "recipe?"',
    description:
      "A recipe is a 'connection' between systems. This can be as little as one system with multiple points, or as many systems as you’d like. Our job is to listen to your needs and design a strategic roadmap that optimizes your business systems with a justifiable ROI.",
  },
]

export const PipefyData = [
  {
    id: 1,
    title: "What is Pipefy?",
    description:
      "Pipefy is a process management tool designed to improve operational processes. It's an intuitive (easy-to-use) low-code platform that helps businesses streamline workflows and processes. The results are profound: improved efficiency, productivity, and profitability.  Businesses that use Pipefy are better positioned to scale growth without scaling costs.",
  },
  {
    id: 2,
    title: "What are the benefits of using Pipefy?",
    description:
      "Pipefy keeps businesses competitive by helping them improve their workflows and processes. With Pipefy, users can build agile systems that give their teams more time and resources to focus on strategic tasks (instead of menial ones). You create the workflows you need using automated templates that can be customized to your needs. The platform also improves team communication with streamlined task management on a single, accessible platform.",
  },
  {
    id: 3,
    title: "What are Pipefy's Pricing Plans?",
    description:
      "Pipefy offers four pricing tiers, depending on the needs of your business:<ul><li>Starter (for small teams): Free</li><li>Business (for mid-sized teams): $25 per user/mo.</li><li>Enterprise (for large-scale teams): Custom</li><li>Unlimited (for businesses that need unlimited power): Custom</li></ul> Learn more about <a href='https://www.pipefy.com/pricing/'>their pricing plans.</a>",
  },
]

export const PeakSuiteData = [
  {
    id: 1,
    title: "What is peakSUITE?",
    description:
      "peakSUITE is a cloud-based business management platform designed to automate all your business processes. Essentially, it eliminates repetitive data entry tasks, providing actionable insight to increase productivity across your entire organization. At its core, peakSUITE is a collection of modularized, pre-built applications in Quickbase. This gives you a head start. We have a range of add-ons and Quickbase enhancements in our peakSUITE offerings. <a aria-label='link' href='/peaksuite/'><i>Learn more.</i></a>",
  },
  {
    id: 2,
    title: "What offerings does peakSUITE have?",
    description:
      "We’re always expanding our list of peakSUITE offerings to meet the growing needs of businesses. You can find a <a aria-label='link' href='/peaksuite/'>full list of those offerings here.</a>",
  },
  {
    id: 3,
    title: "How will peakSUITE fit into my business processes?",
    description:
      "peakSUITE is built for the masses in mind. Everything in peakSUITE is generic and not industry-focused, so it’s ready to work for any and all businesses.",
  },
]

export const QuandaryBuildData = [
  {
    id: 1,
    title: "What is Quandary Build?",
    description:
      "Quandary Build is our application design service. We rapidly build, test, and deploy a variety of low code/no-code custom software builds that fill the gaps in your business. If you feel like your growing business is drowning in inefficient systems and you’re looking to scale effectively, Quandary Build can help. ",
  },
  {
    id: 2,
    title:
      "Will you build my application from scratch or will it be pre-built?",
    description:
      "We offer both! Check out <a aria-label='link' href='/peaksuite/'>our peakSUITE offerings</a> for pre-built applications that can be customized. Or, we can start from scratch. The choice is yours. And if you’re worried about the cost of custom-built applications, don’t be. We use low code and no-code platforms that make it faster and cheaper to build your custom software solutions.",
  },
  {
    id: 3,
    title: "Can my solution be customized?",
    description:
      "Absolutely! All of the platforms we utilize are fully customizable. After our discovery, we’ll provide you with a roadmap of the best solutions that can quickly improve your business. And we’ll discuss any customization options your growing business needs.",
  },
  {
    id: 4,
    title: "How long will it take to build my application?",
    description:
      "Time to development depends on the use case. An application can take anywhere from one day to several weeks, months, or years depending on the need. If there is a definitive timeline, Quandary can ramp up or ramp down development resources as needed to hit your deadline.",
  },
]

export const QuandaryConnectData = [
  {
    id: 1,
    title: "What is Quandary Connect?",
    description:
      "Quandary Connect is our integration design service. We rapidly build, test, and deploy a variety of low code/no-code custom integrations that bridge the gaps in your business systems. If you’re tired of wasting time on manual data entry and processes, Quandary Connect will bridge your disparate systems. Our integrations break down bottlenecks and speed up your processes, giving you more time.",
  },
  {
    id: 2,
    title: "How/where do I host my code?",
    description:
      "Quandary can host your code for you. Or, we can recommend where you can host your own code. This depends on your preference.",
  },
  {
    id: 3,
    title: "How long does an average integration take?",
    description:
      "This will depend on the type and complexity of the integration. Some integrations can take less than 5 hours. Others need a lot more time because of its complexity. We can give you an accurate estimate after our discovery.",
  },
  {
    id: 4,
    title:
      "Is it more cost-effective to use a third-party tool for integrations?",
    description:
      "This depends on the use case. Third-party tools can be more cost-effective if they fit a certain business process need. But custom integrations can also be more cost-effective because they require an ongoing licensing cost. Quandary Consulting Group will work with you to find the best software solutions for your growing business. Our goal is to make it easier and faster for you to do business, freeing up more resources toward future success.",
  },
]

export const TechConsultingData = [
  {
    id: 1,
    title: "What is technology consulting?",
    description:
      "Technology consultants work with growing businesses to help them get more out of technology. The problem with success as a business is that it’s hard to scale. Old systems that you used at the start of your company usually can’t keep up. We prevent businesses from getting tripped up by those systems by building better ones so they can continue to grow.",
  },
  {
    id: 2,
    title: "How can a technology consultant help my business?",
    description:
      "We team up with businesses to give them the advantage. Chances are, you’re facing several challenges to scale your growing business. But, what most businesses don’t realize is that these problems often hint at other issues. As technology consultants, we analyze your business to find its weaknesses and then develop a roadmap for solutions we can build to give you the edge.",
  },
  {
    id: 3,
    title: "When is technology consulting necessary?",
    description:
      "If you don’t have the budget for an Technology department and you need support to scale your business systems, technology consultants can give you what you need at a fraction of what building and maintaining an internal Technology team costs. But it goes beyond satisfying technical demands. If you’re a business owner or department head that spends way too much time at work or you see that your employees are overworked and you want solutions that can give you more freedom, we can help. Our goal is to give our clients more time. Time to focus on what really matters to them.",
  },
  {
    id: 4,
    title: "How do you define a project plan and scope?",
    description:
      "Our team completes an in-depth discovery and analysis to determine exactly what your business needs to achieve its goals. From there, we define a project plan and scope for your review and approval.",
  },
  {
    id: 5,
    title: "Can you help train my staff?",
    description:
      "Yes! In addition to our development services, we offer services where we train your staff on processes, building, best practices, etc. It’s a “do it with me” approach that’s perfect for many business models.",
  },
  {
    id: 6,
    title: "How do you identify what my business needs?",
    description:
      "Our deep dive discovery process combined with our mastery of best practices help us form a roadmap for implementing software, process improvement, automations, and other measures designed to scale your growing business.",
  },
  {
    id: 7,
    title: "Do you handle telecommunications?",
    description:
      "Sadly, no. But if you’re looking for networking, hardware, or telecom support, we can pair you up with a firm that specializes in those offerings specifically.",
  },
]
